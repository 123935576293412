import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["results", "form", "filters", "notice", "alert"]

  connect() {
    if (this.hasNoticeTarget) {
      const notice = this.noticeTarget
      this.timeout = setTimeout(() => {
        notice.classList.add('opacity-0');
        this.timeout = setTimeout(() => {
          notice.remove()
        }, 1000)
      }, 2000)
    }
  }

  close() {
    this.element.classList.add('hidden');
  }

}
