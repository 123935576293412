import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["messageIos", "messageAndroid", "iphoneIcon", "displayMessage"]

  connect() {
    if (this.hasMessageIosTarget || this.hasMessageAndroidTarget) {
      if (!this.isInstalled()) {
        if (this.hasIphoneIconTarget && (this.isIos() || this.isAndroid())) {
          this.displayIphoneIcon();
        }
        if (this.hasDisplayMessageTarget) {
          this.timeout = setTimeout(() => {
            this.toggleMessage();
          }, 800)
        }
      };
    }
  }

  toggleMessage() {
    if (this.isIos()) {
      this.toggle(this.messageIosTarget);
    }
    if (this.isAndroid()) {
      this.toggle(this.messageAndroidTarget);
    }
  }

  toggle(target) {
    if (target.classList.contains('hidden')) {
      target.classList.toggle('hidden');
      this.timeout = setTimeout(() => {
        target.classList.toggle('opacity-0')
      }, 50)
    } else {
      target.classList.toggle('opacity-0')
      this.timeout = setTimeout(() => {
        target.classList.toggle('hidden');
      }, 700)
    }
  };

  isInstalled() {
    if (this.isStandaloneIos()) { return true };
    if (this.isStandaloneAndroid()) { return true };
    return false;
  }

  displayIphoneIcon() {
    this.iphoneIconTarget.classList.remove("hidden");
  }

  isIos() {
    const userAgent = this.userAgent()
    const iosDevice = /iphone|ipad|ipod/.test( userAgent );
    const turboIos = /Turbo-iOS/.test( userAgent )
    if (iosDevice || turboIos) {
      return true
    } else {
      return false
    };
  };

  isAndroid() {
    return /android/i.test( this.userAgent() );
  };

  isStandaloneAndroid() {
    return window.matchMedia('(display-mode: standalone)').matches
  }

  isStandaloneIos() {
    return ('standalone' in window.navigator) && (window.navigator.standalone)
  };

  isTWA() {
    return document.referrer === "android-app://com.iasa_app.twa/" // only when app starts
  };

  userAgent() {
    return window.navigator.userAgent.toLowerCase();
  }

}
