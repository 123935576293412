import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["new", "list", "favorite", "toPublish", "iconHeart", "iconPlus", "next", "currentAddress"]

  connect() {
    const rowParams = window.location.search;
    const urlParams = new URLSearchParams(rowParams);
    if (this.hasFavoriteTarget && urlParams && this.hasCurrentAddressTarget) {
        const currentAddress = this.currentAddressTarget.classList
        if (urlParams.get('update') === 'true') {
          // Change color of address name display bottom to green
          currentAddress.remove("bg-iasa-gray", "bg-opacity-10");
          currentAddress.add("bg-green-300");
          // change back
          setTimeout(function(){
            currentAddress.add("bg-iasa-gray", "bg-opacity-10");
            currentAddress.remove("bg-green-300");
          }, 800);
        } else if (urlParams.get('update') === 'false') {
          console.log("in elsif")
          // Change color of address name display bottom to green
          currentAddress.remove("bg-iasa-gray", "bg-opacity-10");
          currentAddress.add("bg-iasa-red");
          // change back
          setTimeout(function(){
            currentAddress.add("bg-iasa-gray", "bg-opacity-10");
            currentAddress.remove("bg-iasa-red");
          }, 800);
        }
    }
    if (this.hasFavoriteTarget && urlParams && urlParams.get('origin') === 'event_address_show') {
      this.toggle()
    }
  }

  toggle() {
    this.toggleHidden(this.listTarget);
    this.toggleHidden(this.newTarget);
    this.toggleCSSclasses(this.favoriteTarget, "shadow", "bg-white", "bg-black", "bg-opacity-10", "color-dark-gray", "shadow-inner", "text-gray-400", "py-4", "h-8" )
    if (this.hasIconHeartTarget) {
      this.toggleCSSclasses(this.iconHeartTarget, "color-iasa-red", "text-black", "opacity-10", "fa-3x", "fa", "py-4");
    }
    if (this.hasNextTarget) {
      this.toggleHidden(this.nextTarget);
    }
  }

  toggleCSSclasses(el, ...cls) {
    cls.map(cl => el.classList.toggle(cl));
  }

  toggleHidden(el) {
    el.classList.toggle("hidden");
  }



}
