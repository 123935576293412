import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["icon", "iconWrapper", "devise", "waiting", "deviseWrapper", 'iconWaiting', 'iconBase', 'publishWaiting', 'publishBase']

  connect() {
    const rowParams = window.location.search;
    const urlParams = new URLSearchParams(rowParams);
    if (this.hasIconWrapperTarget && urlParams) {
      const iconWrapper = this.iconWrapperTarget.classList
      const icon = this.iconTarget.classList
      if (urlParams.get('update') === 'true') {
        iconWrapper.remove("bg-iasa-blue-action");
        iconWrapper.add("bg-green-300", "transform");
        setTimeout(function(){
          iconWrapper.add("bg-iasa-blue-action");
          iconWrapper.remove("bg-green-300", "transform");
        }, 600);
      } else if (urlParams.get('update') === 'false') {
        iconWrapper.remove("bg-iasa-blue-action");
        iconWrapper.add("bg-iasa-red", "transform");
        icon.remove("fa-check")
        icon.add("fa-times")
        setTimeout(function(){
          iconWrapper.add("bg-iasa-blue-action");
          iconWrapper.remove("bg-iasa-red", "transform");
          icon.add("fa-check")
          icon.remove("fa-times")
        }, 600);
      }
    }
  }

  start() {
    if (this.iconTarget.classList.contains('fa-chevron-right')) {
      this.iconTarget.classList.remove('fa-chevron-right');
    } else if (this.iconTarget.classList.contains('fa-check')) {
      this.iconTarget.classList.remove('fa-check');
    };
    this.iconTarget.classList.add('fa-circle-notch', 'fa-spin')
  }

  add() {
    if (this.hasIconBaseTarget) {
      this.iconBaseTarget.classList.toggle('hidden');
    }
    this.iconWaitingTarget.classList.toggle('hidden');
  }

  spinPublish() {
    if (this.hasPublishBaseTarget) {
      this.publishBaseTarget.classList.toggle('hidden');
    }
    this.publishWaitingTarget.classList.toggle('hidden');
  }

  replace() {
    this.deviseTarget.classList.add('hidden');
    this.deviseWrapperTarget.classList.remove("px-6", "py-2");
    this.deviseWrapperTarget.classList.add("h-10", "w-10");
    this.waitingTarget.classList.remove('hidden');
  }

  toggleIconWrapperColor() {
    this.iconWrapperTarget.classList.toggle("bg-iasa-blue-action");
    this.iconWrapperTarget.classList.toggle("bg-green-300");
  }
}
