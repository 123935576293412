// import CableReady from 'cable_ready'
import consumer from "./consumer"


// consumer.subscriptions.create({"ChatroomChannel", {
//   received(data) {
//     if (data.cableReady) CableReady.perform(data.operations)
//   },
// });

const initChatroomCable = () => {

  const messagesContainer = document.getElementById('messages');
  if (messagesContainer) {
    const chatroomId = messagesContainer.dataset.chatroomId;
    const userId = Number(messagesContainer.dataset.userId);

    consumer.subscriptions.create({ channel: "ChatroomChannel", id: chatroomId }, {
      received(data) {
        let html = "";
        if (userId === data.sender_id) {
          html = data.mine;
        } else {
          html = data.theirs;
        }
        messagesContainer.insertAdjacentHTML('beforeend', html);
        const newMessage = new CustomEvent("new-message");
        document.dispatchEvent(newMessage);
      },
    });
  }
}
export { initChatroomCable };
