import { Controller } from "stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["name", "instagram", "city", "phoneNumber", "description", "bio", "proPageDeletion", "eventsHistory", "subscriptions"]

  visitName () {
    const path = this.nameTarget.getAttribute("href");
    this.visit(path);
  }

  visitInstagram () {
    const path = this.instagramTarget.getAttribute("href");
    this.visit(path);
  }

  visitCity () {
    const path = this.cityTarget.getAttribute("href");
    this.visit(path);
  }

  visitPhoneNumber () {
    const path = this.phoneNumberTarget.getAttribute("href");
    this.visit(path);
  }

  visitDescription () {
    const path = this.descriptionTarget.getAttribute("href");
    this.visit(path);
  }

  visitBio () {
    const path = this.bioTarget.getAttribute("href");
    this.visit(path);
  }

  visitProPageDeletion() {
    const path = this.proPageDeletionTarget.getAttribute("href");
    this.visit(path);
  }

  visitEventsHistory() {
    const path = this.eventsHistoryTarget.getAttribute("href");
    this.visit(path);
  }

  visitSubscriptions() {
    const path = this.subscriptionsTarget.getAttribute("href");
    this.visit(path);
  }


  visit (path) {
    Turbo.visit(path)
  }
}
