import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["stripeCheckoutButton", "iconBase", "iconWaiting"]
  connect() {
  }

  redirectToCheckout(event) {
    const stripe = Stripe(gon.stripe_key);
    const stripeCheckoutButton = this.stripeCheckoutButtonTarget;
    const checkoutSessionId = event.currentTarget.dataset.session;
    if (this.hasIconBaseTarget) {
      this.iconBaseTarget.classList.add("hidden");
    }
    this.iconWaitingTarget.classList.remove("hidden");
    stripe.redirectToCheckout({
      sessionId: checkoutSessionId
    }).then(function (result) {
      console.log(result.error.message);
    });
  }
}



