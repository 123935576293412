import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["messageIos", "messageAndroid"]

  connect() {
    if (this.hasMessageIosTarget || this.hasMessageAndroidTarget) {
      if (!this.isInstalled()) {
        this.timeout = setTimeout(() => {
          this.toggleMessage();
        }, 800)
      };
    }
  }

  toggleMessage() {
    if (this.isIos()) {
      this.toggle(this.messageIosTarget);
    }
    if (this.isAndroid()) {
      this.toggle(this.messageAndroidTarget);
    }
  }

  toggle(target) {
    if (target.classList.contains('hidden')) {
      target.classList.toggle('hidden');
      this.timeout = setTimeout(() => {
        target.classList.toggle('opacity-0')
      }, 50)
    } else {
      target.classList.toggle('opacity-0')
      this.timeout = setTimeout(() => {
        target.classList.toggle('hidden');
      }, 700)
    }
  };

  isIos() {
    return /iphone|ipad|ipod/.test( this.userAgent() );
  };

  isAndroid() {
    return /android/i.test( this.userAgent() );
  };

  userAgent() {
    return window.navigator.userAgent.toLowerCase();
  }

  isInstalled() {
    if (this.isStandaloneIos()) { return true };
    if (this.isStandaloneAndroid()) { return true };
    return false;
  }

  isStandaloneAndroid() {
    return window.matchMedia('(display-mode: standalone)').matches
  }

  isStandaloneIos() {
    return ('standalone' in window.navigator) && (window.navigator.standalone)
  };

}
