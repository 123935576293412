const initMap = () => {
  const mapElementPicker = document.getElementById('map-canvas-picker');
  const mapElementDisplay = document.getElementById('map-canvas');

  // CHECK IF MAP PICKER NEEDED
  if ( mapElementPicker ) {
    const mapContainer = document.getElementById('map-canvas-picker');
    const lat = parseFloat(mapContainer.dataset.lat);
    const lng = parseFloat(mapContainer.dataset.lng);
    const myLatlng = { lat: lat, lng: lng };
    const options = {
      zoom: 16,
      center: myLatlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
      gestureHandling: "greedy"
    }
    var map = new google.maps.Map(mapElementPicker, options);
    var marker = new google.maps.Marker({
          position: myLatlng,
          map: map,
          title: 'Hello World!'
    });
    var infoWindow = new google.maps.InfoWindow();
    google.maps.event.addListener(map, 'center_changed', function() {
        // 0.05 seconds after the center of the map has changed,
        // set back the marker position.
        window.setTimeout(function() {
          var center = map.getCenter();
          marker.setPosition(center);
        }, 50);
    });

    // sleep function for toggling colors when address picked
    function sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }
    async function toggleColors(e) {
      e.classList.remove('bg-white');
      e.classList.add('bg-iasa-blue-action', 'bg-opacity-30');
      await sleep(2000);
      e.classList.remove('bg-iasa-blue-action', 'bg-opacity-30');
      e.classList.add('bg-white');
    }

    const get_address_from_marker = () => {
      // console.log('dans get_address_from_marker')
      const iconLoading = document.getElementById('icon_load_plus_code');
      iconLoading.classList.toggle('hidden');
      const markerLatlng = marker.getPosition();
      const lat = markerLatlng.lat();
      const lng = markerLatlng.lng();
      const key = JSON.parse(mapElementPicker.dataset.key);
      const url_pluscode = `https://plus.codes/api?address=${lat},${lng}&ekey=${key.google_api_encrypted}`
      fetch(url_pluscode)
      .then(function(response) {
        return response.json();
      })
      .then(function(jsonResponse) {
        const plusCode = jsonResponse.plus_code;
        const global_code = plusCode.global_code;
        const globalCodeField = document.getElementById('event_address_plus_code_global')
        const plus_code_lat = plusCode.geometry.location.lat;
        const plus_code_lng = plusCode.geometry.location.lng;
        console.log(global_code);
        globalCodeField.value = global_code;
        document.getElementById('event_address_latitude').value = plus_code_lat;
        document.getElementById('event_address_longitude').value = plus_code_lng;

        if (globalCodeField.classList.contains('bg-green-100')) {
        } else {
          toggleColors(globalCodeField);
        }
        iconLoading.classList.toggle('hidden');
      });
    }
    // ------------------- GET USER'S POSITION
    function handleLocationError(browserHasGeolocation) {
      var content = ""
      if (browserHasGeolocation) {
        content = "Error: The Geolocation service failed."
      } else {
        content = "Error: Your browser doesn't support geolocation."
      };
    // to do : display message in specific div
    };

    function startLoadingSpinner() {
      locationIcon.classList.remove('fa-crosshairs');
      locationIcon.classList.add('fa-circle-notch', 'fa-spin');
    }

    function stopLoadingSpinner() {
      locationIcon.classList.remove('fa-circle-notch', 'fa-spin');
      locationIcon.classList.add('fa-crosshairs');
    }

    const currentPositionButton = document.getElementById("button_get_current_location");
    const locationIcon = document.getElementById("location_icon");
    currentPositionButton.addEventListener("click", () => {
      if (locationIcon.classList.contains('fa-crosshairs')) {
        startLoadingSpinner();
      }
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const pos = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            map.setCenter(pos);
            stopLoadingSpinner();
          },
          () => {
            handleLocationError(true);
            stopLoadingSpinner();
          }
        );
      } else {
        // Browser doesn't support Geolocation
        handleLocationError(false);
        stopLoadingSpinner();
      }

    });

  const get_address_button = document.getElementById("button_get_event_address");
    get_address_button.addEventListener('click', function() {
      get_address_from_marker();
  });

  // CHECK IF MAP DISPLAY NEEDED
  } else if ( mapElementDisplay ) {
    // console.log("in map DISPLAY")
    let map;
    let markers = [];
    let activeInfoWindow;

    function addMarker(marker) {
      /* Extend the bounds for each @marker */
      /* Create a Marker for each @marker sent */
      const event_marker = new google.maps.Marker({
        position: marker.latlng,
        map,
        title: marker.name,
      });
      /* Create infowindow */
      const infowindow = new google.maps.InfoWindow({
        content: marker.info_window_content,
      });

      event_marker.addListener("click", () => {
        if (activeInfoWindow) { activeInfoWindow.close(); }
        infowindow.open(map, event_marker);
        activeInfoWindow = infowindow;
      });
      markers.push(event_marker);
    }

    function deleteMarkers() {
      // console.log(markers);
      markers.forEach((marker) => {
        marker.setMap(null)
      })
      markers = [];
    }

    const zoomChangeBoundsListener = () => {
      google.maps.event.addListenerOnce(map, 'bounds_changed', function(event) {
        // console.log('dans add listener once - zoom here:')
        // console.log(this.getZoom())

        if (this.getZoom() > 14) {
          this.setZoom(14);
        } else if (this.getZoom() < 12) {
          this.setZoom(12)
        }
      });
    }

    const setMarkers = (bounds) => {
        // console.log("dans setMarkers");
        const markersDataElement = document.getElementById('data-markers')
        if (markersDataElement) {
          let datasetMarkers = document.getElementById('data-markers').dataset.markers;
          // console.log(datasetMarkers.length);
          if (datasetMarkers.length == 2) {
            // console.log('pas de markers!')
          } else {
          const markers = JSON.parse(datasetMarkers);
          if (datasetMarkers != "null") {
            markers.forEach((marker) => {
              addMarker(marker);
              bounds.extend(marker.latlng);
            });
            map.fitBounds(bounds);
            zoomChangeBoundsListener();
            }
          }
        }
    };

    // When filters applied: Catch the event sent from stimulus event_search-controller.js
    document.addEventListener("update-markers", function() {
      deleteMarkers();
      const searchBounds = new google.maps.LatLngBounds();
      setMarkers(searchBounds);
    });

    // Map Initializer
    const mapElement = document.getElementById('map-canvas');
    if ( mapElement === null ) {
      // console.log("no map canvas - map.js stopping");
    } else {
      const mapContainer = document.getElementById('map-canvas-container');
      const lat = parseFloat(mapContainer.dataset.lat);
      const lng = parseFloat(mapContainer.dataset.lng);
      const myLatlng = { lat: lat, lng: lng };
      const bounds = new google.maps.LatLngBounds();
      const options = {
        zoom: 14,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        gestureHandling: "greedy"
      }
      map = new google.maps.Map(mapElement, options);
      /* Get the @markers from the view */
      setMarkers(bounds);
      map.fitBounds(bounds);
    };
  } else {
    // console.log("No map.js needed");
  };
};

export { initMap };
