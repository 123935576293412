import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["minimum", "maximum", "price", "attendeesPrice", "earningsMin", "earningsMax", "earningsOne", "priceWrapper", "participantsMin", "participantsMax", "maximumEdit", "minimumEdit", "buttonMinusMinimumEdit", "buttonPlusMinimumEdit", "buttonMinusMaximumEdit", "buttonPlusMaximumEdit", "trialDiscountEnabled"]
  connect() {
    if (this.hasPriceTarget) {
      const price = Number(this.priceTarget.value)
      this.updatePriceColor();
    }
  }

  // FOR EVENT::BUILD#ATTENDEES
  incrementMin() {
    const minimum = this.minimumTarget
    const maximum = this.maximumTarget
    minimum.removeAttribute('readonly');
    minimum.classList.remove('readonly');

    if (Number(minimum.value) < Number(maximum.value))  {
      minimum.value = Number(minimum.value) + 1
    };

    minimum.setAttribute('readonly', 'readonly');
    minimum.classList.add('readonly');
  }

  decrementMin() {
    const minimum = this.minimumTarget
    const maximum = this.maximumTarget
    minimum.removeAttribute('readonly');
    minimum.classList.remove('readonly');

    if (Number(minimum.value) > 1) {
      minimum.value = Number(minimum.value) - 1
    }

    minimum.setAttribute('readonly', 'readonly');
    minimum.classList.add('readonly');
  }

  incrementMax() {
    const minimum = this.minimumTarget
    const maximum = this.maximumTarget
    maximum.removeAttribute('readonly');
    maximum.classList.remove('readonly');
    if (Number(maximum.value) < 30) {
      maximum.value = Number(maximum.value) + 1
    };

    maximum.setAttribute('readonly', 'readonly');
    maximum.classList.add('readonly');
  }

  decrementMax() {
    const minimum = this.minimumTarget
    const maximum = this.maximumTarget
    maximum.removeAttribute('readonly');
    maximum.classList.remove('readonly');
    if (Number(maximum.value) > Number(minimum.value)) {
      if (Number(maximum.value) > 2) {
        maximum.value = Number(maximum.value) - 1
      };
    }

    maximum.setAttribute('readonly', 'readonly');
    maximum.classList.add('readonly');
  }

  incrementMaxEdit() {
    const maximum = this.maximumEditTarget
    maximum.removeAttribute('readonly');
    maximum.classList.remove('readonly');

    if (Number(maximum.value) < 30) {
      maximum.value = Number(maximum.value) + 1
      if (this.buttonMinusMaximumEditTarget.classList.contains("bg-transparent")){
        this.buttonMinusMaximumEditTarget.classList.remove("bg-transparent", "color-gray");
        this.buttonMinusMaximumEditTarget.classList.add("bg-white", "text-iasa-gray")
      }
    };

    if (Number(maximum.value) == 30) {
      if (this.buttonPlusMaximumEditTarget.classList.contains("bg-white")) {
        this.buttonPlusMaximumEditTarget.classList.remove("bg-white", "text-iasa-gray")
        this.buttonPlusMaximumEditTarget.classList.add("bg-transparent", "color-gray")
      }
    }

    maximum.setAttribute('readonly', 'readonly');
    maximum.classList.add('readonly');
  }

  decrementMaxEdit() {
    const maximum = this.maximumEditTarget
    const minimum = this.maximumEditTarget.dataset.value
    maximum.removeAttribute('readonly');
    maximum.classList.remove('readonly');

    if (Number(maximum.value) > Number(minimum)) {
      maximum.value = Number(maximum.value) - 1
      if (this.buttonPlusMaximumEditTarget.classList.contains("bg-transparent")){
        this.buttonPlusMaximumEditTarget.classList.remove("bg-transparent", "color-gray");
        this.buttonPlusMaximumEditTarget.classList.add("bg-white", "text-iasa-gray")
      }
    }

    if (Number(maximum.value) == Number(minimum)) {
      if (this.buttonMinusMaximumEditTarget.classList.contains("bg-white")) {
        this.buttonMinusMaximumEditTarget.classList.remove("bg-white", "text-iasa-gray")
        this.buttonMinusMaximumEditTarget.classList.add("bg-transparent", "color-gray")
      }
    }

    maximum.setAttribute('readonly', 'readonly');
    maximum.classList.add('readonly');
  }

  incrementMinEdit() {
    const minimumField = this.minimumEditTarget
    const maximumValue = this.minimumEditTarget.dataset.value
    minimumField.removeAttribute('readonly');
    minimumField.classList.remove('readonly');
    if (Number(minimumField.value) < Number(maximumValue)) {
      minimumField.value = Number(minimumField.value) + 1
      if (this.buttonMinusMinimumEditTarget.classList.contains("bg-transparent")){
        this.buttonMinusMinimumEditTarget.classList.remove("bg-transparent", "color-gray");
        this.buttonMinusMinimumEditTarget.classList.add("bg-white", "text-iasa-gray")
      }
    }

    if (Number(minimumField.value) == Number(maximumValue)) {
      if (this.buttonPlusMinimumEditTarget.classList.contains("bg-white")) {
        this.buttonPlusMinimumEditTarget.classList.remove("bg-white", "text-iasa-gray")
        this.buttonPlusMinimumEditTarget.classList.add("bg-transparent", "color-gray")
      }
    }
    minimumField.setAttribute('readonly', 'readonly');
    minimumField.classList.add('readonly');
  }

  decrementMinEdit() {
    const minimumField = this.minimumEditTarget
    minimumField.removeAttribute('readonly');
    minimumField.classList.remove('readonly');
    if (Number(minimumField.value) > 1) {
      minimumField.value = Number(minimumField.value) - 1
      if (this.buttonPlusMinimumEditTarget.classList.contains("bg-transparent")){
        this.buttonPlusMinimumEditTarget.classList.remove("bg-transparent", "color-gray");
        this.buttonPlusMinimumEditTarget.classList.add("bg-white", "text-iasa-gray")
      }
    }
    if (Number(minimumField.value) == 1) {
      if (this.buttonMinusMinimumEditTarget.classList.contains("bg-white")) {
        this.buttonMinusMinimumEditTarget.classList.remove("bg-white", "text-iasa-gray")
        this.buttonMinusMinimumEditTarget.classList.add("bg-transparent", "color-gray")
      }
    }
    minimumField.setAttribute('readonly', 'readonly');
    minimumField.classList.add('readonly');
  }
  // FOR EVENT::BUILD#PRICE
  updatePriceValue () {
    const price = Number(this.priceTarget.value)
    const feesFixed = Number(this.priceTarget.dataset.feesFixed) / 100
    const feesVariable = Number(this.priceTarget.dataset.feesVariable)
    const priceOne = price - (price * feesVariable) - feesFixed
    const participantsMin = Number(this.participantsMinTarget.dataset.number)
    const participantsMax = Number(this.participantsMaxTarget.dataset.number)
    const priceOneFixed = priceOne.toFixed(1)
    this.earningsOneTarget.innerHTML = priceOneFixed
    const trialDiscountEnabled = this.trialDiscountEnabledTarget.dataset.value
    if (trialDiscountEnabled === "false") {
      this.earningsMinTarget.innerHTML = (priceOneFixed * participantsMin).toFixed(1)
      this.earningsMaxTarget.innerHTML = (priceOneFixed * participantsMax).toFixed(1)
    } else if (trialDiscountEnabled === "true") {
      this.earningsMinTarget.innerHTML = (price * participantsMin).toFixed(1)
      this.earningsMaxTarget.innerHTML = (price * participantsMax).toFixed(1)
    }
    this.updatePriceColor()
  };

  incrementPrice() {
    const price = this.priceTarget
    price.removeAttribute('readonly');
    price.classList.remove('readonly');

    if (Number(price.value) < 30) {
      price.value = (Number(price.value) + 0.5).toFixed(1);
    };

    price.setAttribute('readonly', 'readonly');
    price.classList.add('readonly');

    //const priceChanged = new CustomEvent("price-changed");
    this.updatePriceValue(price.value)
    // document.dispatchEvent(priceChanged);
  }

  decrementPrice() {
    const price = this.priceTarget
    price.removeAttribute('readonly');
    price.classList.remove('readonly');

    if (Number(price.value) > 4) {
      price.value = (Number(price.value) - 0.5).toFixed(1);
    }

    price.setAttribute('readonly', 'readonly');
    price.classList.add('readonly');
    this.updatePriceValue(price.value)
    //const priceChanged = new CustomEvent("price-changed");
    //document.dispatchEvent(priceChanged);
  }

  updatePriceColor() {
    const price = Number(this.priceTarget.value)
    const classList = this.priceWrapperTarget.classList
    if ( price < 10.5 && !classList.contains("text-green-500") ) {
      classList.remove("text-yellow-500");
      classList.add("text-green-500");
    } else if ( price >= 10.5 && price < 15.5 ) {
      if ( classList.contains("text-green-500")) {
        classList.remove("text-green-500");
        classList.add("text-yellow-500");
      } else if (classList.contains("text-red-500")) {
        classList.remove("text-red-500");
        classList.add("text-yellow-500");
      };
    } else if ( price >= 15.5 ) {
      classList.remove("text-yellow-500");
      classList.remove("text-green-500");
      classList.add("text-red-500");
    };
  }

}
