import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["selectFile", "fileSelected", "fileInput", "image", "imageForm", "notificationForm", "formsContainer", "secondField"]

  connect() {
    console.log("here")
    if (!("Notification" in window)) {
      console.log("inside")
      this.notificationFormTarget.classList.add('hidden')
      if (this.hasSecondFieldTarget) {
        this.secondFieldTarget.classList.add('rounded-t-lg')
      }
    }
  }

  updateImage() {
    const fr = new FileReader()
    const image = this.imageTarget
    fr.onload = function(e) {
      image.setAttribute('src', this.result);
    };
    this.fileInputTarget.onchange = () => {
      const file = this.fileInputTarget.files[0];
      fr.readAsDataURL(file);
      this.selectFileTarget.classList.toggle("hidden");
      this.fileSelectedTarget.classList.toggle("hidden");
      if (window.location.pathname.includes("/user_pages/")) {
        Rails.fire(this.imageFormTarget, 'submit');
      };
    }
  }


}
