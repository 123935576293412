import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["slider", "text", "reviews"];

  translateX() {
    if (this.hasSliderTarget) {
      this.sliderTarget.classList.toggle("translate-x-full");
      console.log(this.textTarget.innerHTML == "Liste")
      if (this.textTarget.innerHTML == "Liste") {
        this.textTarget.innerHTML = "Carte"
      } else if (this.textTarget.innerHTML == "Carte") {
        this.textTarget.innerHTML = "Liste"
      }
    }
  }

  swithToReviews() {
    this.reviewsToggleTarget.click();
  }

  selectDisplay1() {
    document.getElementById("label-display1").click();
  }

  selectDisplay2() {
    document.getElementById("label-display2").click();
  }

  selectDisplay3() {
    document.getElementById("label-display3").click();
  }
}


// TO DO :
