import { Controller } from "stimulus"

var initialPosition = 0;
var newPosition = 0;

export default class extends Controller {
  static targets = ["navbarTop", "scrollArea"]


  connect() {
    if (this.hasNavbarTopTarget && this.hasScrollAreaTarget) {
      initialPosition = this.scrollAreaTarget.getBoundingClientRect().top;
      console.log("initial position:")
      console.log(initialPosition);
      this.changeNavbarTopDesign();
      document.body.addEventListener('scroll', () => {this.changeNavbarTopDesign()}, true);
    }
  }

  changeNavbarTopDesign() {
    console.log("event fired - new position:");

    newPosition = this.scrollAreaTarget.getBoundingClientRect().top
    console.log(newPosition)
    if (initialPosition - newPosition > 20) {
      this.addShadow();
    } else {
      this.removeShadow();
    }
  }

  addShadow() {
    console.log("in add shadow");
    this.navbarTopTarget.classList.add("shadow");
  }

  removeShadow() {
    console.log("in remove shadow");
    this.navbarTopTarget.classList.remove("shadow");
  }

}
