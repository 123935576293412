// probleme: le script ne se lance qu'apres RELOAD de la page
const initializeAutocomplete = () => {

  const elementUser = document.getElementById('user_address_address');
  const elementEvent = document.getElementById('event_address_address');
  const mapElementAutocomplete = document.getElementById('map-canvas-autocomplete')

  function initializeAutocomplete(element) {
    const options = {
      //types: ['address'],
      //bounds: defaultBounds,
      componentRestrictions: { country: "fr" },
      //fields: ["address_components", "geometry", "icon", "name"],
      //origin: center,
      //strictBounds: false,
      //types: ["establishment"],
    };

    if (mapElementAutocomplete) {
      const lat = parseFloat(mapElementAutocomplete.dataset.lat);
      const lng = parseFloat(mapElementAutocomplete.dataset.lng);
      const myLatlng = { lat: lat, lng: lng };
      const options = {
        zoom: 16,
        center: myLatlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        disableDefaultUI: true,
        gestureHandling: "greedy"
      }
      var map = new google.maps.Map(mapElementAutocomplete, options);
    };

    var autocomplete = new google.maps.places.Autocomplete(element, options);

    if (mapElementAutocomplete) {
      autocomplete.bindTo("bounds", map);

      const marker = new google.maps.Marker({
        map,
        anchorPoint: new google.maps.Point(0, -29),
      });

      autocomplete.addListener("place_changed", () => {
        marker.setVisible(false);
        const place = autocomplete.getPlace();
        if (!place.geometry || !place.geometry.location) {
          window.alert("No details available for input: '" + place.name + "'");
          return;
        }
        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(14);
        }
        marker.setPosition(place.geometry.location);
        marker.setVisible(true);
      });
    }

    if (elementEvent) {
      google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChangedEvent);
    } else if (elementUser) {
      google.maps.event.addListener(autocomplete, 'place_changed', onPlaceChangedUser);
    }

  }

  function onPlaceChangedEvent() {
    console.log('onPlaceChanged EVENT');
    var place = this.getPlace();
    for (var i in place.address_components) {
      var component = place.address_components[i];
      for (var j in component.types) {  // Some types are ["country", "political"]
        var type_element = document.getElementById(`event_address_${component.types[j]}`);
        if (type_element) {
          type_element.value = component.long_name;
        }
      }
    }
  }

  function onPlaceChangedUser() {
    console.log('onPlaceChanged USER');
    var place = this.getPlace();
    for (var i in place.address_components) {
      var component = place.address_components[i];
      for (var j in component.types) {  // Some types are ["country", "political"]
        var type_element = document.getElementById(`user_address_${component.types[j]}`);
        if (type_element) {
          type_element.value = component.long_name;
        }
      }
    }
  }

  if (elementEvent) {
    initializeAutocomplete(elementEvent);
  } else if (elementUser) {
    initializeAutocomplete(elementUser);
  };

}

export { initializeAutocomplete }
