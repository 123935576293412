import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["results", "form", "filters", "icon", "rangeValueDisplay", "rangeInput"]

  connect() {
  }

  toggle() {
    const target = this.filtersTarget
    if (target.classList.contains('translate-y-full')) {
      this.toggleCSSclasses(target, "invisible", "translate-y-full")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "duration-200", "blur-2", "bg-iasa-gray")
      }, 150)
    } else {
      this.toggleCSSclasses(target, "blur-2", "bg-iasa-gray", "duration-200")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "translate-y-full")
      }, 1)
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "invisible")
      }, 150)
    }
  }

  // needed only if not using Submit button from form
  search() {
    // Rails.fire(this.formTarget, 'submit');
    this.filtersTarget.classList.toggle("hidden");
    this.iconTarget.classList.toggle("fa-sliders-h");
    this.iconTarget.classList.toggle("fa-times");
  }


  handleResults() {
    const [data, status, xhr] = event.detail
    this.resultsTarget.innerHTML = xhr.response
    const updateMarkers = new CustomEvent("update-markers");
    document.dispatchEvent(updateMarkers);
  }

  toggleCSSclasses(el, ...cls) {
    cls.map(cl => el.classList.toggle(cl));
  }

  updateRangeValue() {
    const newValue = this.rangeInputTarget.value;
    this.rangeValueDisplayTarget.innerHTML = `${newValue} km`;
  }

}
