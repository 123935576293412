import { Controller } from "stimulus"
import "swiper/swiper-bundle.css";
import Swiper, { Pagination, Autoplay } from 'swiper';
Swiper.use([Pagination, Autoplay]);

export default class extends Controller {
  static targets = ["swiper1", "swiper2", "swiperSubscriptions"]

  connect() {
    console.log("in swiper")
    if (this.hasSwiper1Target) {
      const swiper1 = new Swiper('.swiper1', {
        // Optional parameters
        loop: true,
        spaceBetween: 0,
        speed: 1500,
        autoplay: {
          delay: 2000,
        },
        slidesPerView: 1,
        // If we need pagination
        pagination: {
          el: '.pagination1',
        }
      });
    }
    if (this.hasSwiper2Target) {
      const swiper2 = new Swiper('.swiper2', {
        // Optional parameters
        loop: true,
        spaceBetween: 0,
        speed: 1500,
        autoplay: {
          delay: 2000,
        },
        slidesPerView: 1,
        // If we need pagination
        pagination: {
          el: '.pagination2',
        }
      });
    }
    console.log(this.hasSwiperSubscriptionsTarget)
    if (this.hasSwiperSubscriptionsTarget) {
      const swiper2 = new Swiper('.swiper-subscriptions', {
        // Optional parameters
        loop: false,
        spaceBetween: 0,
        slidesPerView: 1,
        // If we need pagination
        pagination: {
          el: '.pagination-subscriptions',
        }
      });
    }
  }

}
