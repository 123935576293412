import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["vapidPublicKey", "p256dhInput", "authInput", "endpointInput", "hasPermissionCheckbox", "notificationForm", "checkboxDot", "hasPermissionInput"]

  connect() {
    if (navigator.serviceWorker) {
      if (navigator.serviceWorker.controller) {
        // If the service worker is already running, skip to state change
        this.stateChange();
      } else {
        // Register the service worker, and wait for it to become active
        this.registerServiceWorker()
        navigator.serviceWorker.addEventListener(
          "controllerchange",
          this.controllerChange.bind(this)
        );
      }
    }
    // to ask only one time : add boolean in notification_setting ? 
    //if (this.hasHasPermissionCheckboxTarget && this.hasPermissionCheckboxTarget.value == '2') {
    //  console.log("going to request for notification");
    //  this.requestNotificationPermission();
    //}
  }

  controllerChange(event) {
    navigator.serviceWorker.controller.addEventListener(
      "statechange",
      this.stateChange.bind(this)
    );
  }

  stateChange() {
    // perform any visual manipulations here
  }


  requestNotificationPermission() {
    if ("Notification" in window) {
      // ADD IF SERVICE WORKER ACTIVATED
      this.checkInput();
      window.Notification.requestPermission();
      const permission = Notification.permission;
      // value of permission can be 'granted', 'default', 'denied'
      // granted: user has accepted the request
      // default: user has dismissed the notification permission popup by clicking on x
      // denied: user has denied the request.
      console.log(permission)
      if (permission !== 'granted') {
          this.uncheckInput();
          this.unregisterServiceWorker();
          this.registerServiceWorker();
          //throw new Error('Permission not granted for Notification');
      } else {
        this.getKeys()
      }
    }
  }

  removeNotificationPermission() {
    this.uncheckInput();
    this.hasPermissionInputTarget.removeAttribute('readonly');
    this.hasPermissionInputTarget.value = "false"
    this.hasPermissionInputTarget.setAttribute('readonly', 'readonly');
    this.notificationFormTarget.submit()
  }

  getKeys() {
    const endpointInput = this.endpointInputTarget
    const p256dhInput = this.p256dhInputTarget
    const authInput = this.authInputTarget
    const hasPermissionInput = this.hasPermissionInputTarget
    const hasPermissionCheckbox = this.hasPermissionCheckboxTarget
    const notificationForm = this.notificationFormTarget
    const rawVapidPublicKey = this.vapidPublicKeyTarget.dataset.value;
    const vapidPublicKey = this.urlB64ToUint8Array(rawVapidPublicKey)
    navigator.serviceWorker.register('/service-worker.js', {scope: '/'})          //5
      .then(function(registration) {
        return registration.pushManager.getSubscription()
          .then(function(subscription) {
            if (subscription) {
              return subscription;
            }
            return registration.pushManager.subscribe({                           //6
              userVisibleOnly: true,
              applicationServerKey: vapidPublicKey
            });
          });
      }).then(function(subscription) {
        const subscriptionJSON = subscription.toJSON()
        const endpoint = subscriptionJSON.endpoint;
        const auth = subscriptionJSON.keys.auth;
        const p256dh = subscriptionJSON.keys.p256dh;
        endpointInput.removeAttribute('readonly');
        p256dhInput.removeAttribute('readonly');
        authInput.removeAttribute('readonly');
        hasPermissionInput.removeAttribute('readonly');
        endpointInput.value = endpoint
        p256dhInput.value = p256dh
        authInput.value = auth
        hasPermissionInput.value = "true"
        hasPermissionCheckbox.value = "1"
        endpointInput.setAttribute('readonly', 'readonly');
        p256dhInput.setAttribute('readonly', 'readonly');
        authInput.setAttribute('readonly', 'readonly');
        hasPermissionInput.setAttribute('readonly', 'readonly');
        notificationForm.submit()
    });
  }


  registerServiceWorker() {
    navigator.serviceWorker
          .register("/service-worker.js", { scope: "/" })
          .then(function (reg) {
            console.log("[Companion]", "Service worker registered! Scope is " + reg.scope);
          });
  }

  unregisterServiceWorker() {
    navigator.serviceWorker
          .getRegistrations()
          .then( function(registrations) { for(let registration of registrations) { registration.unregister(); } });
  }
// urlB64ToUint8Array is a magic function that will encode the base64 public key
// to Array buffer which is needed by the subscription option
  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
    const rawData = atob(base64)
    const outputArray = new Uint8Array(rawData.length)
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i)
    }
    return outputArray
  }

  checkInput() {
    this.hasPermissionCheckboxTarget.checked = true
    //const dotClasslist = this.checkboxDotTarget.classList
    //dotClasslist.add("translate-x-full", "bg-green-300")
  }
  uncheckInput() {
    this.hasPermissionCheckboxTarget.checked = false
    //const dotClasslist = this.checkboxDotTarget.classList
    //dotClasslist.remove("translate-x-full", "bg-green-300")
  }
}
