import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["url", "button"]

  connect() {
  if (!document.queryCommandSupported("copy"))
    this.buttonTarget.classList.toggle("hidden");
  }

  copy() {
  }

  share() {
    console.log("in share()");
    if (navigator.canShare) {
      console.log("navigator.share action")
      const data = {title: document.querySelector('meta[property="og:title"]').content , text: "Joins-toi à moi pour cette séance 💪😃", url: window.location.href};
      navigator.share(data)
          .then(() => console.log('Successful share'),
           error => console.log('Error sharing:', error));
    } else {
      console.log("copy to clipboard action")
      const value = this.urlTarget.value;
      const tempInput = document.createElement("input");
      tempInput.style = "position: absolute; left: -1000px; top: -1000px";
      tempInput.value = value;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand("copy");
      document.body.removeChild(tempInput);
      alert("Lien copié, tu peux partager 😉");
    }
  }

}
