import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["new", "list", "all", "saved", "next", "icon"]

  connect() {
  }

  toggle() {
    this.listTarget.classList.toggle("hidden");
    this.newTarget.classList.toggle("hidden");
    //this.allTarget.classList.toggle("hidden");
    this.nextTarget.classList.toggle("hidden");
    //this.iconTarget.classList.toggle("text-green-300");
    //this.iconTarget.classList.toggle("text-gray-300");
    this.savedTarget.classList.toggle("shadow");
    this.savedTarget.classList.toggle("moving-gradient-soft");
    this.savedTarget.classList.toggle("bg-gray-100");
    this.savedTarget.classList.toggle("color-dark-gray");
    this.savedTarget.classList.toggle("shadow-inner");
    this.savedTarget.classList.toggle("border");
    this.savedTarget.classList.toggle("border-gray-300");
    this.savedTarget.classList.toggle("text-gray-400");
  }


}
