import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["gridItem"]

  connect() {
    //const gridItems = document.querySelectorAll(".grid-background > div");
    const gridItems = this.gridItemTargets
    gridItems.forEach((item) => {
      // calculate random number for delay
      const delay = this.getRandomInt(0, 5);
      // calculate random number for duration
      const duration = this.getRandomInt(3, 6);
      // set both
      item.style.animationDelay = `${delay}s`;
      item.style.animationDuration = `${duration}s`;
    })
  }

  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
