import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    const fixContent = "height=device-height, width=device-width, initial-scale=1, viewport-fit=cover, user-scalable=0"
    const meta_viewport = document.querySelector('meta[name="viewport"]')
    meta_viewport.setAttribute("content", fixContent);
  }

  disconnect() {
    const initialContent = "height=device-height, width=device-width, initial-scale=1, user-scalable=0"
    const meta_viewport = document.querySelector('meta[name="viewport"]')
    meta_viewport.setAttribute("content", initialContent);
  }

}
