import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "label"]


  increment() {
    let placesRemaining = Number(document.getElementById('event').dataset.placesRemaining);
    const target = this.inputTarget
    target.removeAttribute('readonly');
    target.classList.remove('readonly');
    if (target.value < placesRemaining) {
      target.value = Number(target.value) + 1
    } else {
      target.value = Number(target.value)
    };
    if (target.value > 1) {
      this.labelTarget.innerHTML = "personnes"
    } else {
      this.labelTarget.innerHTML = "personne"
    }

    target.setAttribute('readonly', 'readonly');
    target.classList.add('readonly');
  }

  decrement() {
    let numberOfPlaces = Number(document.getElementById('event').dataset.placesRemaining);
    const target = this.inputTarget
    target.removeAttribute('readonly');
    target.classList.remove('readonly');
    if (target.value <= 1) {
      target.value = Number(target.value)
    } else {
      target.value = Number(target.value) - 1
    };

    if (target.value > 1) {
      this.labelTarget.innerHTML = "personnes"
    } else {
      this.labelTarget.innerHTML = "personne"
    }
    target.setAttribute('readonly', 'readonly');
    target.classList.add('readonly');
  }
}
