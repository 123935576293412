import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["info", "menu", "attendeesList", "followersList", "organizersList", "membersList", "trialBanner", "termsOfService", "image", "imageWrapper", "actionModal", "infoStripeAccount", "infoStripeSubscription"]

  connect() {
    if (this.hasMenuTarget) {
      const menu = this.menuTarget
      if (!menu.classList.contains('-translate-x-full')) {
        menu.classList.toggle('-translate-x-full');
      }
    }
  }

  disconnect() {
    if (this.hasMenuTarget) {
      const menu = this.menuTarget
      if (!menu.classList.contains('-translate-x-full')) {
        menu.classList.toggle('-translate-x-full');
      }
    }
  }

  toggleInfo() {
    // _modal-help_map_picker ; _modal-help_attendees ; _modal-help_price ;
    //this.toggle(this.infoTarget, true, "faded")
    this.translateY(this.infoTarget)
  }

  toggleBanner() {
    this.toggle(this.trialBannerTarget, false)}

  toggleMenu() {
    this.translateX(this.menuTarget)
  }

  toggleActionModal() {
    this.translateY(this.actionModalTarget);
  }

  toggleAttendeesList() {
    this.translateY(this.attendeesListTarget);
  }

  toggleFollowersList() {
    this.translateY(this.followersListTarget)
  }

  toggleOrganizersList() {
    this.translateY(this.organizersListTarget)
  }

  toggleMembersList() {
    this.translateYhidden(this.membersListTarget)
  }

  toggleTermsOfService() {
    this.toggle(this.termsOfServiceTarget, true, "faded")
  }

  toggleInfoStripeAccount() {
    this.translateY(this.infoStripeAccountTarget);
  }

  toggleInfoStripeSubscription() {
    this.translateY(this.infoStripeSubscriptionTarget);
  }

  toggle(target, transition, transitionType) {
    if (transition) {
      if (transitionType == "faded") {
        if (target.classList.contains('hidden')) {
          target.classList.toggle('hidden');
          this.timeout = setTimeout(() => {
            target.classList.toggle('opacity-0')
          }, 5)
        } else {
          target.classList.toggle('opacity-0')
          this.timeout = setTimeout(() => {
            target.classList.toggle('hidden');
          }, 200)
        }
      }
    } else {
      target.classList.toggle('hidden');
    }
  }

  translateX(target) {
    if (target.classList.contains('-translate-x-full')) {
      this.toggleCSSclasses(target, "-translate-x-full")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "duration-200", "blur-3", "bg-iasa-gray")
      }, 150)
    } else {
      this.toggleCSSclasses(target, "blur-3", "bg-iasa-gray", "duration-200")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "-translate-x-full")
      }, 1)
    }
  }

  translateY(target) {
    if (target.classList.contains('translate-y-full')) {
      this.toggleCSSclasses(target, "invisible", "translate-y-full")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "duration-200", "blur-3", "bg-iasa-gray")
      }, 150)
    } else {
      this.toggleCSSclasses(target, "blur-3", "bg-iasa-gray", "duration-200")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "translate-y-full")
      }, 1)
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "invisible")
      }, 150)
    }
  }

  translateYhidden(target) {
    if (target.classList.contains('translate-y-full')) {
      this.toggleCSSclasses(target, "hidden", "translate-y-full")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "duration-200", "blur-3", "bg-iasa-gray")
      }, 150)
    } else {
      this.toggleCSSclasses(target, "blur-3", "bg-iasa-gray", "duration-200")
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "translate-y-full")
      }, 1)
      this.timeout = setTimeout(() => {
        this.toggleCSSclasses(target, "hidden")
      }, 150)
    }
  }

  toggleImage() {
    this.toggleCSSclasses(this.imageWrapperTarget, "inline-block", "flex-shrink-0", "relative", "absolute", "top-0", "bottom-0", "right-0", "left-0", "z-20", "bg-black", "bg-opacity-10", "blur-10", "flex", "items-center", "justify-center", "px-8");
    this.toggleCSSclasses(this.imageTarget, "h-20", "w-20", "h-auto", "w-full");
  }

  toggleCSSclasses(el, ...cls) {
    cls.map(cl => el.classList.toggle(cl));
  }

}
