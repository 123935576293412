import { Controller } from "stimulus"

let deviceInnerHeight;

export default class extends Controller {


  connect() {
    //if (isInStandaloneMode()) {
    //  if (isIos() && !document.body.classList.contains("safe-area")) {
    //    document.body.classList.add("safe-area");
    //  }
    // } else {
      this.resize();
    //};
  }

  resize() {
    console.log("viewport resize")
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  center() {
    window.scrollTo(0, 0);
  }

  isInStandaloneMode() {
    ('standalone' in window.navigator) && (window.navigator.standalone);
  }

  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
}
