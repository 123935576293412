import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["star1", "star2", "star3", "star4", "star5", "ratingValue"]

  one() {
    this.set_stars(1)
  }

  two() {
    this.set_stars(2)
  }

  three() {
    this.set_stars(3)
  }

  four() {
    this.set_stars(4)
  }

  five() {
    this.set_stars(5)
  }

  set_stars(number) {
    const stars = [this.star1Target, this.star2Target, this.star3Target, this.star4Target, this.star5Target]
    const stars_to_check = stars.slice(0, number);
    const stars_to_uncheck = stars.slice(number);
    this.check(stars_to_check)
    this.uncheck(stars_to_uncheck)
    this.update_value(number)
  }

  check(stars) {
    stars.forEach((star) => {
      if (!star.classList.contains('text-yellow-400')) {
        star.classList.toggle('text-yellow-400')
      };
    });
  }

  uncheck(stars) {
    if (stars.length >= 1) {
      stars.forEach((star) => {
        if (star.classList.contains('text-yellow-400')) {
          star.classList.toggle('text-yellow-400')
        };
      });
    }
  }

  update_value(number) {
    this.ratingValueTarget.value = number
  }

}
