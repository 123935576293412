// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.Rails = require("@rails/ujs")
import Turbo from "@hotwired/turbo"
//window.Turbo = Turbo
require("@rails/activestorage").start()
require("channels")

// import { Turbo, cable } from "@hotwired/turbo-rails";
import { Application } from "stimulus";
import  "stylesheets/application"
import  "controllers"
import { initChatroomCable } from "channels/chatroom_channel";
import { initializeAutocomplete } from "./autocomplete";
import { initMap } from "./map";
// GOOGLE FONTS
require("typeface-comfortaa")
// Swiper JS
//import { initSwiper } from './swiper'
Rails.start()

const application = Application.start()

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

document.addEventListener('turbo:load', () => {
  initChatroomCable();
  initializeAutocomplete();
  initMap();
  //initSwiper();
});
