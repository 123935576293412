import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["subscribe", "unsubscribe", "response"]

  handleResponse () {
    const [data, status, xhr] = event.detail
    this.responseTarget.innerHTML = data.subscription
  }


}
