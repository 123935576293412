import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["navbar", "menuBar1", "menuBar2", "menuBar3", "menuDropDown", "socialNetworkIcon", "heroSection"]

  connect() {
    this.changeMenuDesign();
    document.body.addEventListener('scroll', () => {this.changeMenuDesign()});
  }

  changeMenuDesign() {
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      this.blurMenu();
    } else {
      this.unblurMenu();
    }
  }

  blurMenu() {
    this.menuDropDownTarget.classList.add("blur-5rem", "shadow");
  }

  unblurMenu() {
    this.menuDropDownTarget.classList.remove("blur-5rem", "shadow");
  }

  scrollToHeroSection() {
    console.log("in SCROLL TO");
    this.heroSectionTarget.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  toggleMenu() {
    if (!this.menuDropDownTarget.classList.contains("blur-5rem")) {
      this.blurMenu();
    }
    this.toggleMenuIcon();
    this.toggleMenuDropDown();
    this.toggleSocialIcons();
    if (!this.isTopPosition() && !this.menuIsExpanded()) {
      this.timeout = setTimeout(() => {
        this.unblurMenu();
      }, 300)
    };
  }

  toggleMenuIcon() {
    this.toggleCSSclasses(this.menuBar1Target, "rotate-45", "-translate-y-1.5")
    this.toggleCSSclasses(this.menuBar2Target, "opacity-0")
    this.toggleCSSclasses(this.menuBar3Target, "-rotate-45", "translate-y-1.5")
  }

  toggleMenuDropDown() {
    this.toggleCSSclasses(this.menuDropDownTarget, "expanded")
  }

  toggleSocialIcons() {
    const socialIcons = this.socialNetworkIconTargets
    socialIcons.forEach((icon) =>{
      this.toggleCSSclasses(icon, "hidden")
    })
  }

  toggleCSSclasses(el, ...cls) {
    cls.map(cl => el.classList.toggle(cl));
  }

  isTopPosition() {
    return document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
  }

  menuIsExpanded() {
    return this.menuDropDownTarget.classList.contains("expanded");
  }
}
