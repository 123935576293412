import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["passwordIcon", "passwordInput", "passwordConfirmationIcon", "passwordConfirmationInput"];

  togglePassword() {
    var icon = this.passwordIconTarget;
    var input = this.passwordInputTarget;
    this.toggle(input, icon)
  }

  togglePasswordConfirmation() {
    var icon = this.passwordConfirmationIconTarget;
    var input = this.passwordConfirmationInputTarget;
    this.toggle(input, icon)
  }

  toggle(input, icon) {
    if (icon.classList.contains("fa-eye-slash")) {
      input.type = "password";
      icon.classList.remove("fa-eye-slash");
      icon.classList.add("fa-eye");
    } else {
      input.type = "text";
      icon.classList.remove("fa-eye");
      icon.classList.add("fa-eye-slash");
    }
  }
}


// TO DO :
