import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["currentLocationIcon", "currentLocationIconWrapper", "inputLat", "inputLng", "inputIsGeolocation", "form", "messagesContainer"]

  connect() {
    this.scrollDown()
  }

  getCurrentPosition() {
    //const result = confirm("📍 Partager ta position actuelle ?");
    //if (result) {
      const locationIcon = this.currentLocationIconTarget
      if (!locationIcon.classList.contains('hidden')) {
        this.toggleLoadingSpinner();
      }
      // Try HTML5 geolocation.
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            // Set All Fields
            this.inputLatTarget.value = position.coords.latitude
            this.inputLngTarget.value = position.coords.longitude
            this.setIsGeolocationTo(true);
            // Submit Form
            Rails.fire(this.formTarget, 'submit')
            // Reset All fields
            this.inputLatTarget.value = ""
            this.inputLngTarget.value = ""
            this.setIsGeolocationTo(false);
            // Stop Loading Icon
            this.toggleLoadingSpinner();
          },
          () => {
            //this.handleLocationError(true);
            this.toggleLoadingSpinner();
          }
        );
      } else {
        // Browser doesn't support Geolocation
        //this.handleLocationError(false);
        this.toggleLoadingSpinner();
      }
    //}
  };

  scrollDown() {
    this.messagesContainerTarget.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  setIsGeolocationTo(boolean) {
    const isGeolocation = this.inputIsGeolocationTarget
    isGeolocation.removeAttribute('readonly')
    isGeolocation.value = boolean
    isGeolocation.setAttribute('readonly', 'readonly');
  }

  toggleLoadingSpinner() {
    const locationIcon = this.currentLocationIconTarget
    const iconWrapper = this.currentLocationIconWrapperTarget
    locationIcon.classList.toggle('hidden');
    iconWrapper.classList.toggle('fa-circle-notch');
    iconWrapper.classList.toggle('fa-spin');
  };

  handleLocationError(browserHasGeolocation) {
    var content = ""
    if (browserHasGeolocation) {
      content = "Error: The Geolocation service failed."
    } else {
      content = "Error: Your browser doesn't support geolocation."
    };
  // to do : display message in specific div
  };

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  };
}



